import React from 'react'

import DefaultLayout from '../../components/layout'

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props

    return (
      <DefaultLayout>
        <h2 className="text-2xl font-bold mb-6 border-l-8 pl-4 py-2 border-teal-600 bg-gray-100">
          text-2xl font-bold mb-6 border-l-8 pl-4 py-2 border-teal-600
          bg-gray-100
        </h2>
        <h3 className="text-xl font-bold mb-6 border-l-8 pl-4 py-1 border-teal-600">
          text-xl font-bold mb-6 border-l-8 pl-4 py-1 border-teal-600
        </h3>
        <h4 className="text-lg font-bold mb-6">text-lg font-bold mb-6</h4>
        <ul className="mb-6 p-4 bg-indigo-100 rounded border border-dashed border-indigo-600">
          <li className="list-disc list-inside leading-loose">
            list-disc list-inside leading-loose
          </li>
        </ul>
        <blockquote className="bg-gray-200 p-4 border border-dashed border-gray-500 rounded mb-6">
          bg-gray-200 p-4 border border-dashed border-gray-500 rounded mb-6
        </blockquote>
        <div className="flex justify-center">
          <button className="bg-teal-600 text-white rounded p-2 mb-6">
            bg-teal-600 text-white rounded p-2 mb-6
          </button>
        </div>
        <a href="/" className="border rounded flex w-full h-32">
          <div className="w-3/4 p-3 border-r">
            <div className="text-xl ">タイトル</div>
            <div className="mt-3">説明</div>
          </div>
          <div>
            <img src=""></img>
          </div>
        </a>
      </DefaultLayout>
    )
  }
}

export default NotFoundPage
